.main_jobs{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 2% 0;
    color: $color-white;

    .main_jobs_content{
        height: auto;
        margin: 0 auto;

        .main_jobs_title{
            padding: 0;
            margin: 0 auto;
            text-align: center;

            h2{
                font-size: 3em;
                line-height: 1.2;
                font-weight: 700;
                padding: 1% 0;
                margin-bottom: 5%;
            }

        }

        .main_jobs_desc{
            padding: 0;
            margin: 0 auto 2% auto;
            display: flex;
            flex-direction: column;

            .line_h{
                width: 100%;
                height: 10px;
                position: absolute;
            }
            
            h2{
                width: 25%;
                margin: 0 auto;
                position: relative;
                text-align: center;
                padding: 0;
                background: $color-black;
                z-index: 1;
                margin-top: calc(-1% - .4%);
                margin-bottom: $margin-bottom-two-percent;
            }
            p{
                padding: 4%;
                text-align: center;
            }

            .content{
                margin: 0 auto;
            }

        }
        .midia h2{
            width: 10%;
        }
        .planejamento h2{
            width: 45%;
        }
        .creators h2{
            width: 12%;
        }
        .inbound h2{
            width: 22%;
        }
        .sites{
            .btn{
                width: auto;
                max-width: 90%;
                margin: 0 auto;
                a{
                    color: $color-white;
                }
            }
            .border-linear{
                background: linear-gradient($color-black, $color-black) padding-box,
                $color-gradiente border-box;
                border-radius: 50em;
                 border: 4px solid transparent;
                }
            h2{
                width: 45%;
            }
        }

    }
}


