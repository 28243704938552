.btn{
    border-radius: 30px;
    min-height: 50px;
    height: auto;
    padding: 6px 12px;
    outline: none;
    border: 2px solid;
    font-size: 0.875em;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    transition: .5s;
    
    &:hover{
        @if $button-hover-type == 'darken'{
            background: darken(#666, $button-hover-amount);
        } @else{
            background: lighten(#666, $button-hover-amount);
        }
    }
    
    a{
        text-decoration: none;
    }
    
}

@mixin button($color){
    background: #{$color};
    
    &:hover{
        @if $button-hover-type == 'darken'{
            background: darken($color, $button-hover-amount);
        } @else{
            background: lighten($color, $button-hover-amount);
        }
    }
}


@each $color, $value in $theme-font-colors{
    .btn-#{$color}{
        @include button($value);

    }
}
.btn-larger{
    min-width: 55%;
}