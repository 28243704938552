.main_contact{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 2% 0;
    background: $color-gradiente;

    .main_contact_content{
        height: auto;
        margin: 0 auto;

        .main_contact_title{
            width: 100%;

            h2{
                font-size: 3em;
                font-weight: 700;
                padding: 1% 0;
                text-align: center;
            }

            p{
                width: 60%;
                text-align: center;
                margin: 0 auto;
                font-size: 1em;
            }

        }

        .main_contact_form{
            width: 60%;
            height: auto;
            margin: 0 auto;
            padding: 2%;
            z-index: 10;

            input[type=text],
            input[type=email],
            input[type=tel]{
                width: 100%;
                height: 50px;
                margin: 5px 0;
                padding: 10px 20px;
                border-radius: 30px;
                border: none;
                font-size: 0.875em;
            }
            ::placeholder{
                color: $color-black;
            }
            input[type=submit]{
                width: 30%;
                height: 50px;
                margin: 5px auto;
                padding: 10px 20px;
                border-radius: 30px;
                border: 3px solid $color-white;
                cursor: pointer;
                background: transparent;
                text-transform: uppercase;
                font-size: 0.875em;
            }

        }

    }
}


