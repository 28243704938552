.main_footer{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 4% 0;
    background: $color-black;

    .main_footer_whatsapp{
        width: 80px;
        height: 80px;
        background: $color-green;
        border-radius: 50%;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 7%;
        bottom: 5%;
        z-index: 2;

        a{
            font-size: 2.5em;
            color: $color-white;
            display: block;
            padding: 10px 20px;
        }
    }

    .main_footer_content{
        height: auto;
        margin: 0 auto;

        .main_footer_social{
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            margin-bottom: $margin-bottom-two-percent;

            span{
                width: 60px;
                height: 60px;
                padding: 5px;
                margin: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 3em;
                // background: $color-orange;

                .fa-brands{
                    background: -webkit-linear-gradient($color-blue-light, $color-blue);
                    background: linear-gradient($color-blue-light, $color-blue);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

            }
        }

        .main_footer_address{
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $color-white;
            text-align: center;
            p{
                font-size: 1em;
                margin-bottom: 3%;
                a{
                    padding: 0;
                    color: $color-white;
                    text-decoration: none;
                }
            }
            img{
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}


