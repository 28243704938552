$color-blue: #004FFF;
$color-blue-light: #00FFFF;
$color-blue-dark: #004F91;
$color-gray: #666;
$color-orange: #f90;
$color-black: rgba(0,0,0);
$color-red: #900;
$color-white: #fff;
$color-green: #25D366;
$color-gradiente: linear-gradient(to right, $color-blue-light, $color-blue);


$theme-font-colors: () !default;
$theme-font-colors: map_merge(
    (
        "blue": $color-blue,
        "gray": $color-gray,
        "orange": $color-orange,
        "white": $color-white,
    ), $theme-font-colors
);

$background: () !default;
$background: map_merge((
    "white": $color-white,
    "black": $color-black,
), $background);

$gradients: () !default;
$gradients: map_merge((
    "blue": linear-gradient(to right, $color-blue-light, $color-blue),
    "line": linear-gradient(to right, $color-blue-light, $color-blue),
    ), $gradients);


$fa-font-path: "../webfonts";

    
$button-hover-type: 'darken';
$button-hover-amount: 10%;

$gradient-hover-type: 'darken';
$gradient-hover-amount: 5%;

$margin-bottom-two-percent: 2%;



