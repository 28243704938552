.main_about{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 2% 0;

    .main_about_content{
        height: auto;
        margin: 0 auto;

        .main_about_title{
            width: 100%;

            h2{
                font-size: 3em;
                font-weight: 700;
                padding: 1% 0;
                text-align: center;
                margin-bottom: $margin-bottom-two-percent;
            }

        }

        .main_about_desc{
            padding: 2% 0;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;

            .main_about_img{
                display: flex;
                align-items: center;

                img{
                    display: flex;
                    width: 100%;
                    height: 100%;
                    margin: 10px;
                }
            }


            p{
                width: 60%;
                margin: 2% 0;
                padding: 2% 0;
                text-align: center;
            }


            .border-linear-desc{
                width: 22%;
                background: linear-gradient($color-white, $color-white) padding-box,
                $color-gradiente border-box;
                border-radius: 50em;
                border: 2px solid transparent;
            }

            .line_h{
                width: 70%;
                margin: 2% 0;
                height: 2px;
            }

        }

    }
}


