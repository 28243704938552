@media (max-width: 1080px){

    .main_banner{
        padding: 0;
        background-size: cover;
        background-position: top center;
    

    }

    .main_clients_button{
        .btn{
            min-width: 50%;
        }
    }

}
@media (max-width: 870px){
    .main_banner{
        background: rgba(0,0,0,1);
        border-bottom: 20px solid;
        border-bottom-color: #00ffff;
        .main_banner_content{
            .main_banner_left{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }
    }

    .main_company{
        .main_company_content{
            .main_company_flex{
                .main_company_desc{
                    width: 100%;
                    padding: 4% 0;
                    z-index: 1;
                    margin-top: 0;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    background: none;
                    p{
                        text-align: center;
                    }
                    button{
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    .main_differentials{
        .main_differentials_content{
            .main_differentials_desc{
                justify-content: space-around;
                .main_differentials_round{
                    width: 200px;
                    height: 200px;
    
                    h2{
                        font-size: 1em;
                        line-height: 1.5;
                    }
                }
            }
        }
    }


    .main_about{
        .main_about_content{
            .main_about_desc{
    
                p{
                    width: 90%;
                }

                .btn{
                    min-width: 40%;
                }
        
                .line_h{
                    width: 100%;
                    margin: 4% 0;
                }
            }
        }
    }
    

    .main_clients{
        .main_clients_content{
            .main_clients_logo{
                width: 100%;
                    img{
                        margin: 20px;
                    }
            }
    
            .main_clients_button{
    
                button{
                    min-width: 60%;
                }
            }
        }
    }
    
    
    .main_contact{
        .main_contact_content{
            .main_contact_title{
                p{
                    width: 80%;
                }
            }
    
            .main_contact_form{
                width: 90%;
                input[type=submit]{
                    min-width: 40%;
                    margin: 5px auto;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    .main_clients_button{
        .btn{
            min-width: 50%;
        }
    }
}

@media (max-width: 603px){
    .main_company{
        .main_company_content{
            .main_company_flex{
                .main_company_desc{
                    width: 100%;
                    padding: 4% 0;
                    z-index: 1;
                    margin-top: 0;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    background: none;
                    p{
                        text-align: center;
                    }
                    button{
                        margin: 0 auto;
                    }
                }
            }
        }
    }



    .main_jobs{
        .main_jobs_content{
            .main_jobs_desc{
                h2{
                    width: 70%;
                }
            }
            .midia h2{
                width: 50%;
            }
            .planejamento h2{
                width: 80%;
            }
            .creators h2{
                width: 50%;
            }
            .inbound h2{
                width: 60%;
            }
            .sites{
                .btn{
                    min-width: 50%;
                }
                h2{
                    width: 80%;
                }
            }
        }
    }
    
 
    .main_about{
        .main_about_content{
            .main_about_desc{
    
                p{
                    width: 90%;
                }

                .btn{
                    min-width: 60%;
                }
        
                .main_about_img{
                    height: 100%;
                    display: flex;
                    max-width: 40%;
                    max-height: 40%;
                    flex-wrap: wrap;
                    margin: 10px;
                }
            }
        }
    }
    

    .main_clients{
        .main_clients_content{
            .main_clients_logo{
                width: 100%;
                    img{
                        margin: 20px;
                    }
            }
    
            .main_clients_button{
    
                button{
                    min-width: 100%;
                }
            }
        }
    }
    
    
    .main_contact{
        .main_contact_content{
            .main_contact_title{
                p{
                    width: 80%;
                }
            }
    
            .main_contact_form{
                width: 90%;
                input[type=submit]{
                    min-width: 40%;
                    margin: 5px auto;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    .main_clients_button{
        .btn{
            min-width: 90%;
        }
    }
}