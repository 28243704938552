*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    ul{
        list-style: none;
        a{
            text-decoration: none;
        }
    }
}
html{
    @media (prefers-reduced-motion: no-preference) {
        html {
          scroll-behavior: smooth;
        }
      }
      
      :target {
        scroll-margin-top: .8em;
      }
    }
.content{
    width: 86%;
}