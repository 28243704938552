@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


// @font-face {
//     font-family: 'Poppins', sans-serif;
//     src: url(Poppins-Black.ttf);
//     src: url(Poppins-BlackItalic.ttf);
//     src: url(Poppins-Bold.ttf);
//     src: url(Poppins-BoldItalic.ttf);
//     src: url(Poppins-ExtraBold.ttf);
//     src: url(Poppins-ExtraBoldItalic.ttf);
//     src: url(Poppins-ExtraLight.ttf);
//     src: url(Poppins-ExtraLightItalic.ttf);
//     src: url(Poppins-Italic.ttf);
//     src: url(Poppins-Light.ttf);
//     src: url(Poppins-LightItalic.ttf);
//     src: url(Poppins-Medium.ttf);
//     src: url(Poppins-MediumItalic.ttf);
//     src: url(Poppins-Regular.ttf);
//     src: url(Poppins-SemiBold.ttf);
//     src: url(Poppins-SemiBoldItalic.ttf);
//     src: url(Poppins-Thin.ttf);
//     src: url(Poppins-ThinItalic.ttf);
// }