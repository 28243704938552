.main_header{
    width: 100%;

    .main_header_content{
        height: auto;
        margin: 0 auto;

        .main_header_nav{
            width: 100%;
            height: 140px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .main_header_nav_logo{
                display: flex;
                padding: 1% 0;
                align-items: center;

                img{
                    display: flex;
                    max-width: 70%;
                    max-height: 70%;
                }
            }

            ul{
                display: flex;

                li{
                    font-size: 1em;
                    text-transform: uppercase;

                    a{
                        color: $color-black;
                        padding: 10px;
                    }
                }
            }
        }
    }
}