.main_banner{
    width: 100%;
    min-height: 795px;
    margin: 0;
    padding: 2% 0;
    background-image: url("../images/bg-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-content: center;
    align-items: center;

    
    .main_banner_content{
        height: auto;
        margin: 0 auto;

        .main_banner_left{
            width: 50%;
            height: 100%;
            color: #fff;
            padding: 4% 0;

            h1{
                font-size: 3em;
                line-height: 1.2;
                font-weight: 700;
                padding: 1% 0;
                margin-bottom: $margin-bottom-two-percent;
            }

            h2{
                font-size: 2em;
                font-weight: 600;
                line-height: 1.2;
                margin-bottom: $margin-bottom-two-percent;
            }

            p{
                font-size: 1em;
                font-weight: 300;
                padding: 10px 0;
                margin-bottom: $margin-bottom-two-percent;
            }
            .btn{
                display: block;
                a{
                    color: $color-black;
                }
                &:hover{
                    a{
                        color: $color-white;
                    }
                }
            }
        }
    }
}


@mixin gradients($color, $hover: #000, $hover-white: #fff){
    
    background: #{$color};
    border: none;
    color: $color-white;
    
    &:hover{
        @if $gradient-hover-type == 'darken'{
            background: linear-gradient(to left, $color-blue-dark, $color-blue), $gradient-hover-amount;
        } @else{
            background: linear-gradient(to right, $color-blue-light, $color-blue), $gradient-hover-amount;
        }
    }
}
@each $color, $value in $gradients{
    .gradients-#{$color}{
        @include gradients($value);
    }
}
