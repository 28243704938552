.main_company{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 2% 0;

    .main_company_content{
        height: auto;
        margin: 0 auto;

        .main_company_title{
            width: 100%;

            h1{
                font-size: 3em;
                line-height: 1.2;
                font-weight: 700;
                padding: 1% 0;
                margin-bottom: $margin-bottom-two-percent;
            }

        }

        .main_company_flex{
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;

            .main_company_image{
                width: 100%;
                background: #069;
                display: flex;
    
                img{
                    max-width: 100%;
                    max-height: 100%;
                    display: flex;
                }
            }
            .main_company_desc{
                width: 60%;
                padding: 4% 8%;
                // background: ;
                z-index: 1;
                margin-top: calc(-10% - 4%);

                p{
                    padding:$margin-bottom-two-percent;
                    font-size: 1em;
                }

                button{
                    margin-top: 2%;
                    width: auto;

                    a{
                        color: $color-white;
                    }
                }
            }
    

        }

    }
}

@mixin background($color){
    background: #{$color};
}


@each $color, $value in $background{
    .background-#{$color}{
        @include background($value);
    }
}
