.main_differentials{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 2% 0;

    .main_differentials_content{
        height: auto;
        margin: 0 auto;

        .main_differentials_title{
            width: 100%;

            h2{
                font-size: 3em;
                font-weight: 700;
                padding: 1% 0;
                text-align: center;
                margin-bottom: $margin-bottom-two-percent;
            }

        }

        .main_differentials_desc{
            padding: 2% 0;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .main_differentials_round{
                width: 280px;
                height: 280px;
                margin: 10px;
                padding: 10px;
                background: linear-gradient($color-white, $color-white) padding-box,
                $color-gradiente border-box;
                border-radius: 50%;
                border: 6px solid transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                h2{
                    font-size: 1.5em;
                    font-weight: 500;
                    line-height: 1.3;
                }
            }
        }

    }
}


