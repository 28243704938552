.main_clients{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 2% 0;

    .main_clients_content{
        height: auto;
        margin: 0 auto;

        .main_clients_title{
            width: 100%;

            h2{
                font-size: 3em;
                font-weight: 700;
                padding: 1% 0;
                text-align: center;
                margin-bottom: $margin-bottom-two-percent;
            }

        }

        .main_clients_logo{
            padding: 2% 0;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

                img{
                    display: flex;
                    max-width: 100%;
                    max-height: 100%;
                    margin: 15px;
                }

        }

        .main_clients_button{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2% 0;

            button{
                width: 30%;
                height: auto;
                padding: 1% 2%;

                a{
                    color: $color-white;
                }
            }
        }

    }
}


